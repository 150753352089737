import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  TextField,
  TextareaAutosize,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import HelpIcon from "@mui/icons-material/Help";
import { getDateTimeFromServer } from "./utils";

import { useCreateReducer } from "../../../hooks/useCreateReducer.js";
import ViewDatasheet from "../viewDatasheet";
import CMCTable from "./CMCTable";
import DetailsOfCalStandards from "./DetailsOfCalStandards";
import Gauge from "./Gauge";
import ObservedReadings from "./ObservedReadings/ObservedReadings.js";
import { historyModalStyle, modalStyle, useStyles } from "./constants";
import { DatasheetContext } from "./datasheet.context.js";
import "./datasheet.css";
import { initialState } from "./datasheet.state.js";
import {
  fetchAllowedStandards,
  fetchCertificateDetails,
  fetchDatasheet,
  fetchDisciplineDetails,
  fetchInstrument,
  fetchSettings,
  fetchUnits,
  getReferenceData,
  fetchDatasheetReadingsAdditionalInfo,
} from "./fetchHandlers.js";
import {
  processDatasheetReadings,
  validateAndSubmitData,
} from "./submitHandlers.js";
import moment from "moment";
import OpinionAndAnalysisTable from "./opinionAndAnalysisTable.js";
import { editdataSheetGuide } from "../guide.js";

function DatasheetForm(props) {
  const printComponentRef = React.useRef();
  const { datasheetId, isPrint } = useParams();
  const editdataSheetGuides = editdataSheetGuide();
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: {
      unitSymbols,
      srfDetails,
      datasheetDetails,
      certificateDetails,
      disciplineDetails,
      standardMasterDetails,
      indicators,
      calibratedBy,
      settings,
      instrumentDetails,
      isProcess,
      referenceData,
      DatasheetReadingsAdditionalInfo,
      staticTables
    },
    dispatch,
  } = datasheetContextValue;

  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalHistoryOpen, setModalHistoryOpen] = React.useState(false);
  const [isPrinting, setisPrinting] = React.useState(false);

  const showAmendmentModal = () => setModalOpen(true);
  const closeAmendmentModal = () => setModalOpen(false);
  const handleHistoryOpen = () => setModalHistoryOpen(true);
  const handleHistoryClose = () => setModalHistoryOpen(false);

  const userType = localStorage.getItem("type");

  const handleAmendment = async () => {
    closeAmendmentModal();
    try {
      setLoader(true);
      let res = await validateAndSubmitData(
        datasheetContextValue,
        showAmendmentModal
      );
      setLoader(false);
      if (res == true) {
        window.location.reload(false);
      }
    } catch (error) {
      console.error(
        "[ERROR] while validating and submitting datasheet data, error: ",
        error
      );
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchDatasheet(datasheetId, dispatch);
    fetchSettings(indicators, dispatch);
    getReferenceData(datasheetId, dispatch);
    fetchCertificateDetails(datasheetId, dispatch);
  }, []);

  useEffect(() => {
    if (standardMasterDetails?.standardMasterIds?.length > 0 && Object.keys(referenceData).length > 0) {
      fetchAllowedStandards(standardMasterDetails.standardMasterIds, dispatch , referenceData);
    }
  }, [standardMasterDetails?.standardMasterIds,referenceData]);

  useEffect(() => {
    if (datasheetDetails?.instrumentId > 0 && Object.keys(referenceData).length > 0) {
      fetchInstrument(datasheetDetails.instrumentId, dispatch, referenceData);
    }
  }, [datasheetDetails?.instrumentId,referenceData]);

  useEffect(() => {
    if (datasheetDetails?.disciplineId > 0) {
      fetchDisciplineDetails(datasheetDetails.disciplineId, dispatch);
      fetchUnits(datasheetDetails.disciplineId, dispatch);
    }
  }, [datasheetDetails.disciplineId]);

  useEffect(() => {
    if (datasheetDetails?.id) {
      fetchDatasheetReadingsAdditionalInfo(datasheetDetails?.id,dispatch)
    }
  }, [datasheetDetails.id]);

  const renderAmendmentModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={(e, r) => {
          if (r !== "backdropClick") closeAmendmentModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <TextField
            id="outlined-basic"
            label="Reason For Amendment"
            defaultValue={datasheetDetails?.reasonForAmendment}
            multiline
            maxRows={5}
            rows={5}
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "5px" }}
            onChange={(e) => {
              dispatch({
                field: "datasheetDetails",
                value: {
                  ...datasheetDetails,
                  reasonForAmendment: e.target.value,
                },
              });
            }}
          />
          <Tooltip title="Print Datasheet" placement="top-start">
            <span>
              <Button
                variant="contained"
                size="small"
                sx={{ m: 0 }}
                onClick={handleAmendment}
                disabled={datasheetDetails?.reasonForAmendment === ""}
              >
                Next
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Modal>
    );
  };

  const renderAmendmentHistoryModal = () => {
    return (
      <Modal
        open={modalHistoryOpen}
        onClose={(e, r) => {
          handleHistoryClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={historyModalStyle}>
          <div style={{ maxHeight: "100%", height: "300px" }}>
            <h4 style={{ marginBottom: "10px", height: "30px" }}>
              Amendment History
            </h4>
            <div style={{ overflow: "auto", height: "235px" }}>
              {datasheetDetails?.amendmentHistory ? (
                <table style={{ margin: "auto" }}>
                  <tr>
                    <th style={{ padding: "5px" }}>Sr. No.</th>
                    <th style={{ padding: "5px" }}>Amendment Remark</th>
                    <th style={{ padding: "5px" }}>Date</th>
                  </tr>
                  {datasheetDetails?.amendmentHistory
                    .split("\n")
                    .map((e, i) => (
                      <tr>
                        <td style={{ padding: "5px" }}>{i + 1}</td>
                        <td style={{ padding: "5px" }}>{e.split(",")[0]}</td>
                        <td style={{ padding: "5px" }}>{e.split(",")[1]}</td>
                      </tr>
                    ))}
                </table>
              ) : (
                "No Amendment History Found!"
              )}
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const renderForm = () => {
    return (
      <div>
        <Grid
          container
          spacing={2}
          sx={{ my: 2 }}
          id="datasheet_editdatasheet_firstdisabled"
        >
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Issue Number"
              size="small"
              value={disciplineDetails?.issueNo}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Customer Name"
              size="small"
              value={datasheetDetails?.clientName || ""}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Service Request Number"
              className="textfield"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={certificateDetails?.serviceReqNumber}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                dispatch({
                  field: "certificateDetails",
                  value: {
                    ...certificateDetails,
                    serviceReqNumber: e.target.value,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Job Number"
              className="textfield"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={datasheetDetails?.jobNumber}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Certificate Number"
              InputLabelProps={{ shrink: true }}
              value={certificateDetails?.certificateNumber}
              onChange={(e) => {
                dispatch({
                  field: "certificateDetails",
                  value: {
                    ...certificateDetails,
                    certificateNumber: e.target.value,
                  },
                });
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="ULR Number"
              InputLabelProps={{ shrink: true }}
              value={certificateDetails?.ULRNo}
              onChange={(e) => {
                dispatch({
                  field: "certificateDetails",
                  value: {  
                    ...certificateDetails,
                    ULRNo: e.target.value,
                  },
                });
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ my: 1 }}>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_dateofissue"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="Date Of Issue"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={
                  datasheetDetails?.dateOfIssue
                    ? new Date(datasheetDetails?.dateOfIssue)
                    : ""
                }
                onChange={(e) => {
                  dispatch({
                    field: "datasheetDetails",
                    value: {
                      ...datasheetDetails,
                      dateOfIssue: e,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_datepo"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="PO Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={
                  datasheetDetails?.poDate
                    ? new Date(datasheetDetails?.poDate)
                    : ""
                }
                onChange={(e) => {
                  dispatch({
                    field: "datasheetDetails",
                    value: {
                      ...datasheetDetails,
                      poDate: e,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_dateofcallibration"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="Date of calibration"
                maxDate={new Date()}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={
                  datasheetDetails?.calibrationDate
                    ? new Date(datasheetDetails?.calibrationDate)
                    : ""
                }
                onChange={async (e) => {
                  // 1. prepare calibration date
                  let calibrationDateTime = await getDateTimeFromServer();
                  calibrationDateTime.setUTCFullYear(e.getUTCFullYear());
                  calibrationDateTime.setUTCMonth(e.getUTCMonth());
                  calibrationDateTime.setUTCDate(e.getUTCDate());
                              
                  if (!datasheetDetails?.calibrationDate) {
                    calibrationDateTime.setDate(calibrationDateTime.getDate() + 1);
                  }

                  // 2. if due date is not set, set it to  calibration date + calibration frequency
                  let nextDueDate = datasheetDetails?.nextDueDate;
                  if (true || !nextDueDate) {
                    nextDueDate = new Date(
                      moment(calibrationDateTime)
                        .add(
                          datasheetDetails?.calibrationFrequency?.split(
                            " "
                          )?.[0] || 0,
                          "M"
                        )
                        .subtract(1, 'd')
                        .format("YYYY-MM-DD")
                    );
                  }

                  dispatch({
                    field: "datasheetDetails",
                    value: {
                      ...datasheetDetails,
                      calibrationDate: calibrationDateTime,
                      nextDueDate: nextDueDate,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_dateofnextduedate"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="Next due date"
                value={datasheetDetails?.nextDueDate}
                onChange={(e) => {
                  dispatch({
                    field: "datasheetDetails",
                    value: {
                      ...datasheetDetails,
                      nextDueDate: e,
                    },
                  });
                }}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <hr />
        <Grid
          container
          spacing={2}
          sx={{ my: 2 }}
          id="datasheet_editdatasheet_seconddisabled"
        >
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Instrument Name"
              size="small"
              value={datasheetDetails?.instrumentName || ""}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Requested Name"
              size="small"
              value={datasheetDetails?.requestedname || ""}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                dispatch({
                  field: "datasheetDetails",
                  value: {
                    ...datasheetDetails,
                    requestedname: e.target.value,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Calibration Procedure No."
              size="small"
              value={datasheetDetails?.calProcRefNo ? datasheetDetails?.calProcRefNo : datasheetDetails?.calibrationProcedureNo || ""}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                dispatch({
                  field: "datasheetDetails",
                  value: {
                    ...datasheetDetails,
                    calProcRefNo: e.target.value,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Referenecer Standards"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={datasheetDetails?.isaStandard ? datasheetDetails?.isaStandard : datasheetDetails?.referenceStandards || ""}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                dispatch({
                  field: "datasheetDetails",
                  value: {
                    ...datasheetDetails,
                    isaStandard: e.target.value,
                  },
                });
              }}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Make"
              value={datasheetDetails?.make || ""}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Model"
              value={datasheetDetails?.model || ""}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Con Of Duc"
              value={datasheetDetails?.conOfDuc || ""}
              className="textfield"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Range"
              value={datasheetDetails?.ranges
                ?.replaceAll("||", ", ")
                ?.replaceAll("|", " to ")
                ?.replaceAll("#", "")}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="LC"
              value={datasheetDetails?.lc
                ?.replaceAll("||", ", ")
                ?.replaceAll("|", " to ")
                ?.replaceAll("#", "")}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Serial Number"
              value={datasheetDetails?.serialNo || ""}
              className="textfield"
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="DUC ID"
              value={datasheetDetails?.DUCID || ""}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Location"
              value={datasheetDetails?.location || ""}
              className="textfield"
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Accuracy"
              value={(datasheetDetails?.accuracy || "")
                ?.replaceAll("||", ", ")
                ?.replaceAll("|", " to ")
                ?.replaceAll("#", "")}
              className="textfield"
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Cal Point"
              value={datasheetDetails?.calPoint || ""}
              className="textfield"
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Cal Method"
              value={datasheetDetails?.calMethod || ""}
              className="textfield"
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="outlined-basic"
              label="Location Of Instrument"
              value={datasheetDetails?.locationOfInstrument || ""}
              className="textfield"
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ my: 2 }}>
          {datasheetDetails?.extraColumns?.map((col, id) => (
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                id="outlined-basic"
                label={col[0]}
                value={col[1]}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_starttemprature"
          >
            <TextField
              label="Start temprature (°C)"
              variant="outlined"
              value={datasheetDetails?.startTemp?.split("#")[0] || ""}
              onChange={(e) => {
                dispatch({
                  field: "datasheetDetails",
                  value: {
                    ...datasheetDetails,
                    startTemp: `${e.target.value}#°C`,
                  },
                });
              }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_endtemprature"
          >
            <TextField
              label="End temprature (°C)"
              variant="outlined"
              value={datasheetDetails?.endTemp?.split("#")[0] || ""}
              onChange={(e) => {
                dispatch({
                  field: "datasheetDetails",
                  value: {
                    ...datasheetDetails,
                    endTemp: `${e.target.value}#°C`,
                  },
                });
              }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_starthHumidity"
          >
            <TextField
              id="outlined-basic"
              label="Start Humidity (%Rh)"
              value={datasheetDetails?.startHumidity?.split("#")[0] || ""}
              onChange={(e) => {
                dispatch({
                  field: "datasheetDetails",
                  value: {
                    ...datasheetDetails,
                    startHumidity: `${e.target.value}#%Rh`,
                  },
                });
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_endhHumidity"
          >
            <TextField
              id="outlined-basic"
              label="End Humidity (%Rh)"
              value={datasheetDetails?.endHumidity?.split("#")[0] || ""}
              onChange={(e) => {
                dispatch({
                  field: "datasheetDetails",
                  value: {
                    ...datasheetDetails,
                    endHumidity: `${e.target.value}#%Rh`,
                  },
                });
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_atmospheric"
          >
            <TextField
              id="outlined-basic"
              label="Atmospheric Pressure"
              value={datasheetDetails?.atmosphericPressure || ""}
              onChange={(e) => {
                dispatch({
                  field: "datasheetDetails",
                  value: {
                    ...datasheetDetails,
                    atmosphericPressure: e.target.value,
                  },
                });
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={3}
            id="datasheet_editdatasheet_discipline"
          >
            <TextField
              id="outlined-basic"
              label="Discipline"
              disabled
              value={datasheetDetails?.disciplineName || ""}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div id="datasheet_editdatasheet_details_of_calibration">
          <DetailsOfCalStandards isPrinting={isPrinting} />
        </div>
        <br />
        <hr />
        <Gauge />
        <ObservedReadings datasheetId={datasheetId} isPrinting={isPrinting} />
        <hr />
        <CMCTable instrumentId={datasheetDetails.instrumentId} />
        {settings?.["Opinion And Analysis Table"] === "true" && (
          <OpinionAndAnalysisTable datasheetId={datasheetId} />
        )}
        <div
          style={{ marginBottom: "50px" }}
          id="datasheet_editdatasheet_remarks"
        >
          <div style={{ float: "left" }}>
            <h5>Remarks :</h5>
          </div>
          <br />
          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder="Enter remark here"
            style={{ width: "100%", float: "left", padding: "5px" }}
            defaultValue={
              datasheetDetails?.configuration?.remark ||
              datasheetDetails?.defaultReamrk ||
              instrumentDetails?.instrumentRemark
            }
            onBlur={(e) => {
              dispatch({
                field: "datasheetDetails",
                value: {
                  ...datasheetDetails,
                  configuration: {
                    ...datasheetDetails?.configuration,
                    remark: e.target.value,
                  },
                },
              });
            }}
          />
        </div>
        {/* <div>
          <Grid container spacing={2}>
            <Grid item>
              <b>Last Modified : </b>
            </Grid>
            <Grid item>
              {showLastModified ? moment(lastModified).format("mm/DD/yyyy") : ""}
            </Grid>
          </Grid>
        </div> */}

        {indicators?.isCalTypeSetting &&
          settings?.["CalibrationTypeOptions"]?.split(",")?.length > 0 && (
            <div>
              <Grid container spacing={2}>
                <Grid
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  item
                  style={{ marginTop: "5px" }}
                >
                  <h5>Calibration Type:</h5>
                </Grid>
                {settings?.["CalibrationTypeOptions"]
                  ?.split(",")
                  ?.map((option, index) => (
                    <Grid item xs={4} sm={4} md={2} lg={2} key={index}>
                      <FormControlLabel
                        classes={{
                          justifyContent: "space-between",
                          alignItems: "left",
                        }}
                        control={
                          <Checkbox
                            checked={
                              datasheetDetails?.calibrationReason === index + 1
                            }
                            onChange={(e) => {
                              dispatch({
                                field: "datasheetDetails",
                                value: {
                                  ...datasheetDetails,
                                  calibrationReason: index + 1,
                                },
                              });
                            }}
                          />
                        }
                        label={option}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          )}

        <hr
          style={{ float: "left", border: "1px solid black", width: "100%" }}
        />

{
  DatasheetReadingsAdditionalInfo?.length > 0 && (
    <>
      <Grid container spacing={2}>
        {DatasheetReadingsAdditionalInfo?.sort((a, b) => a.rowIndex - b.rowIndex)?.map((item, index) => {
          const { tableId, datasheetReadingId, rowIndex, ...rest } = item;
          const matchingTable = tableId ? staticTables?.find(table => table?.id === parseInt(tableId)) : null;
          
          if (!matchingTable) return null;

          const filteredEntries = Object?.entries(rest)?.filter(([key, value]) => value !== null && !['rowIndex', 'status', 'lastModified']?.includes(key));
          
          return (
            <Grid key={index} item xs={12} style={{ marginBottom: '20px' }}>
              <Grid container spacing={1}>
                {filteredEntries?.map(([key, value]) => {
                  const imageData = value ? JSON?.parse(value) : {};

                  return (
                    <Grid key={key} item xs={12} md={6} lg={3}>
                      <>
                        <h6>{`Table Name:${matchingTable?.tableName}`}</h6>
                        <h6>{`Column Name:${matchingTable[key] || key}`}</h6>
                        <h6>{`Row Index:${rowIndex}`}</h6>
                      </>
                      <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", width: "100%", height: "300px", padding: "5px" }}>
                        <img src={imageData?.image} alt={`Image of ${key}`} style={{ width: "100%", height: "87%" }} />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <hr style={{ float: "left", border: "1px solid black", width: "100%" }} />
    </>
  )
}





        <Grid container spacing={2} sx={{ my: 3 }}>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="datasheet_editdatasheet_calibratedby"
              label="Calibrated by"
              size="small"
              fullWidth
              variant="outlined"
              value={calibratedBy || ""}
              disabled
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <TextField
              id="datasheet_editdatasheet_revno"
              label="Rev. Number"
              value={
                datasheetDetails?.revisionNumber == -1
                  ? 0
                  : datasheetDetails?.revisionNumber || ""
              }
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    if (isPrint === "print") {
      setisPrinting(true);
    }
  }, [isPrint]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              editdataSheetGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      {loader && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            background: "rgba(255,255,255, 0.85)",
            zIndex: "2",
          }}
        >
          <CircularProgress style={{ width: "70px", height: "70px" }} />
        </div>
      )}
      {!isPrinting && (
        <Paper sx={{ mx: 2, mt: 2, p: 2 }} ref={printComponentRef}>
          <div>
            <Typography variant="h5" component="div" sx={{ mb: 1 }}>
              Datasheet of {datasheetDetails?.instrumentName}
            </Typography>
            <div style={{ float: "right" }}>
              {settings?.["Customer Annexure"]?.value !== "true" &&
                srfDetails?.customerAnnextureFilePaths
                  ?.split(",")
                  ?.map((filePath, index) => {
                    return (
                      <span>
                        <a href={filePath}>Annexture form {index + 1}</a>
                        &nbsp;&nbsp;
                      </span>
                    );
                  })}
            </div>
          </div>
          <br />
          <hr />
          {renderForm()}
          <hr />
          <Toolbar
            style={{
              padding: "0px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {(`${userType}` === "1" ||
              `${userType}` === "4" ||
              props.rolesInfo?.currentRole?.id == 1) && (
              <>
                <Button
                  id="datasheet_editdatasheet_processdatasheet"
                  variant="contained"
                  size="small"
                  sx={{ ml: 3, p: "5px", minWidth: "130px", py: 1 }}
                  onClick={async () => {
                    setLoader(true);

                    let res = await processDatasheetReadings(datasheetContextValue);
                    if (res) {
                      dispatch({
                        field: "staticReadingRows",
                        value: [...res],
                      });
                    }
                    setLoader(false);

                    dispatch({
                      field: "isProcess",
                      value: true,
                    });
                  }}
                >
                  Process
                </Button>
                <Button
                  id="datasheet_editdatasheet_savedatasheet"
                  variant="contained"
                  size="small"
                  // disabled={isProcess}
                  sx={{ ml: 3, p: "5px", minWidth: "130px", py: 1 }}
                  onClick={async () => {
                    setLoader(true);
                    let res = await validateAndSubmitData(
                      datasheetContextValue,
                      showAmendmentModal
                    );
                    setLoader(false);
                    if (res == true) {
                      window.location.reload(false);
                    }
                  }}
                >
                  {!isProcess ? "Save as Draft" : "Save"}
                </Button>
              </>
            )}

            {indicators?.isShowAmendment && (
              <Button
                id="datasheet_editdatasheet_amendment"
                variant="contained"
                size="small"
                sx={{ ml: 3 }}
                onClick={() => {
                  handleHistoryOpen();
                }}
              >
                Amendment History
              </Button>
            )}
          </Toolbar>
          {renderAmendmentModal()}
          {renderAmendmentHistoryModal()}
        </Paper>
      )}
      {isPrinting && (
        <ViewDatasheet datasheetId={datasheetId} isPrinting={isPrinting} />
      )}
    </>
  );
}

export default function EditDatasheet(props) {
  const contextValue = useCreateReducer({
    initialState,
  });
  return (
    <DatasheetContext.Provider value={{ ...contextValue }}>
      <DatasheetForm {...props} />
    </DatasheetContext.Provider>
  );
}
