var resistance;

resistance = {
  "Ω": {
    name: {
      singular: 'Ohm',
      plural: 'Ohms'
    },
    to_anchor: 1
  },
  "mΩ": {
    name: {
      singular: 'Milliohm',
      plural: 'Milliohms'
    },
    to_anchor: 0.001
  },
  "kΩ": {
    name: {
      singular: 'Kiloohm',
      plural: 'Kiloohms'
    },
    to_anchor: 1000
  },
  "MΩs": {
    name: {
      singular: 'Megaohm',
      plural: 'Megaohms'
    },
    to_anchor: 1000000
  }
};

module.exports = {
  metric: resistance,
  _anchors: {
    metric: {
      unit: 'Ω',
      ratio: 1
    }
  }
};
