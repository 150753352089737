import { evaluate } from "mathjs";
import { BASE_URL, MAX_COLS } from "../../../global";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

const BASE = 1; // 1 minus actual columns for 0 based indexing

const maxColumns = MAX_COLS;
const extraPrecisionSplitter = " + ";

const getStandardRangeValue = (
  columnKey,
  standardRanges,
  standardRangeId,
  stdRangePref = 0
) => {
  standardRangeId = standardRangeId
    ?.split(",")
    [stdRangePref > 0 ? stdRangePref - 1 : 0]?.split(":")[1];

  let range = standardRanges.filter(
    (range) => range.rangeId == Number(standardRangeId)
  );

  let value = null;
  if (columnKey === "all") {
    value = range[0];
  } else {
    value = range[0]?.[columnKey]?.split("#")[0] || 0;
  }

  return value;
};

function resolveFormulaConditions(
  formulas,
  referenceData,
  tableIndex,
  readingRow,
  unit_row,
  standardRanges
) {
  // Note: currently supporting operators: ==

  let selectedFormulas = {};
  Object.keys(formulas).forEach((column) => {
    if (typeof formulas?.[column] === "string") {
      selectedFormulas[column] = formulas[column];
    } else {
      Object.keys(formulas?.[column]).forEach((condition) => {
        if (condition != "default") {
          let subConditions = condition.split(" && ");

          let result = [];
          subConditions.forEach((subCondition) => {
            // validate condition
            let tokens = subCondition.split(" == ");
            // for single comparison
            if (tokens.length === 2) {
              let lhs = tokens[0].split(".");

              // find LHS value
              let lhs_val = null;

              if (lhs.includes("standardName")) {
                lhs_val = standardRanges
                  .map((standard) => standard.title.trim())
                  .join("||");
              } else if (lhs.includes("selectedStandardName")) {
                let selectedRangeIds =
                  readingRow[readingRow.length - 2].split(",");

                let selectedStandardRanges = standardRanges.filter((range) =>
                  selectedRangeIds.includes(`${range.id}:${range.rangeId}`)
                );

                lhs_val = selectedStandardRanges
                  .map((standard) => standard.title.trim())
                  .join("||");
              } else if (lhs.length === 1) {
                lhs_val = lhs;
              } else if (lhs.length === 2) {
                if (lhs[0] === "ds") {
                  let colIndex = lhs[1].split("c")[1];
                  lhs_val = readingRow[Number(colIndex) + BASE];
                } else {
                  lhs_val = referenceData[lhs[0]]?.[lhs[1]];
                }
              } else if (lhs.length === 3) {
                if (lhs[0] === "ds") {
                  if (unit_row.length < 1) return;

                  let colIndex = lhs[1].split("c")[1];
                  lhs_val = unit_row?.[Number(colIndex) + BASE];
                  lhs_val = lhs_val?.split("_unit_")[1];
                } else {
                  lhs_val = referenceData[lhs[0]]?.[lhs[1]];
                  lhs_val = lhs_val?.split("||");
                  if (lhs[2] === "unit" && lhs_val) {
                    if (lhs_val?.length > 1) {
                      lhs_val = lhs_val[tableIndex]?.split("#")[1];
                    } else {
                      lhs_val = lhs_val[0]?.split("#")[1];
                    }
                  }
                }
              }

              // find RHS value
              let rhs =
                typeof tokens == "string" ? tokens[1].split(".") : tokens[1];
              let rhs_val = null;
              if (typeof rhs == "string") {
                rhs_val = rhs.trim();
              } else {
                if (rhs.length === 1) {
                  rhs_val = rhs[0];
                } else if (rhs.length === 2) {
                  rhs_val = referenceData[rhs[0]][rhs[1]];
                } else if (rhs.length === 3) {
                  rhs_val = referenceData[rhs[0]][rhs[1]];
                  if (rhs[2] === "unit") {
                    rhs_val = rhs_val.split(" ")[1];
                  }
                }
              }

              // compare LHS and RHS
              if (lhs_val?.includes("||") && lhs_val?.includes(rhs_val)) {
                selectedFormulas[column] = formulas[column][condition];
                result.push(true);
              } else if (lhs_val == rhs_val) {
                result.push(true);
              } else {
                result.push(false);
              }
            }
          });
          if (!result.includes(false)) {
            selectedFormulas[column] = formulas[column][condition];
          }
        }
      });
      if (!selectedFormulas[column]) {
        selectedFormulas[column] = formulas[column]["default"];
      }
    }
  });
  return selectedFormulas;
}

const resolveReferenceTableColumns = (
  formula,
  element,
  referenceData,
  standardRanges
) => {
  // 1. resolve standard ranges table cols;
  if (formula?.includes("standardRanges")) {
    let fullColumn = formula.match(/standardRanges.[a-zA-Z.]+/);
    fullColumn?.forEach((column) => {
      let value = getStandardRangeValue(
        column.split(".")?.[1],
        standardRanges,
        element[element.length - 2]
      );
      formula = formula.replace(column, value);
    });
  }
  return formula;
};

function resolveFormulas(
  readings,
  datasheetObservedReadingFallbackValue,
  config,
  precisionCount,
  datasheet,
  referenceData,
  standardRanges,
  uncertaintyIndex
) {
  let _originalConfig = config;

  config.forEach((config) => {
    let tableId = config.tableId;
    let original_formulas = config.formulas;
    let tableIndex = config.index;
    let unit_row = [];

    readings.forEach((element) => {
      // skip reading if it is not for current table
      if (element[0] != tableId) return;

      // skip for units
      if (String(element[2])?.includes("_unit_")) {
        unit_row = element;
        return;
      }

      let formulas = resolveFormulaConditions(
        original_formulas,
        referenceData,
        tableIndex,
        element,
        unit_row,
        standardRanges
      );
      Object.keys(formulas).forEach((column, rIndex) => {
        let index = Number(column.split("c")[1]) + BASE;

        let formula = formulas[column];
        if (element[0] === tableId) {
          // replace table columns with actual values
          formula = resolveReferenceTableColumns(
            formula,
            element,
            referenceData,
            standardRanges
          );

          // replace datasheet fields with values
          Object.keys(datasheet || {}).forEach((key) => {
            if (formula.includes(key)) {
              let dsKey = datasheet[key];
              // pick 1 value from multi values if any
              dsKey = String(dsKey)?.replace("\n", "").split("||");
              dsKey = dsKey[tableIndex] || dsKey[0];

              // remove unit from dsKey if it contains
              dsKey = String(dsKey).split("#")[0];
              formula = formula.replaceAll(key, Number(dsKey) || 0);
            }
          });

          let level = 1;
          let isInit = true;
          do {
            // replace column representaitons with values
            for (let j = maxColumns - 1; j >= 0; j--) {
              let _formula = formula;
              if (formula?.includes("c" + j)) {
                _formula = formula.replaceAll("c" + j, element[j + BASE]);
              }
              if (isInit && formula !== _formula) level += 1;
              formula = _formula;
            }

            isInit = false;

            // resolve -- terms
            formula = formula.replaceAll("--", "+");

            // redefine math formulas
            for (const val of ["sqrt", "pow", "max", "min", "abs"]) {
              formula = formula.replaceAll(val, "Math." + val);
              // TODO: added quick for multile Math. in formula
              formula = formula.replaceAll("Math.Math.", "Math.");
            }

            // redefine custom math formulas
            if (formula.includes("std")) {
              // calculate math.std(c1,c2,c3) with math.std library function and replace it with result
              formula = evaluate(formula);
              formula = String(formula);
            }

            // miscellanious
            formula = formula.replaceAll(":", ",");
            formula = formula.replaceAll("$", "");

            // resolve custom functions
            if (formula.includes("avg")) {
              let token = formula.match(/avg\((.*?)\)/);
              token = token?.[1];
              let values = token?.split(",");
              let sum = 0;
              let count = 0;
              values.forEach((value) => {
                if (value != "" && Number(value) == value) {
                  sum += Number(value);
                  count += 1;
                }
              });
              let avg = sum / count;
              formula = formula.replace(`avg(${token})`, avg);
            }

            try {
              // eslint-disable-next-line no-eval
              if (config?.customPrecision[column]?.split(".")[0] == 7) {
                element[index] = Number(eval(formula)).toString();
                let elm = element[index].split(".");
                if (elm.length > 1 && elm[1].length > 4) {
                  elm[1] = elm[1].slice(0, 4);
                  element[index] = elm.join(".");
                }
              } else {
                element[index] = Number(eval(formula)).toFixed(precisionCount);
              }

              if (element[index]?.includes("Infinity")) {
                element[index] = "0";
              } else if (isNaN(element[index])) {
                element[index] = datasheetObservedReadingFallbackValue || "";
              }

              let tmpElement = element;
              let hasDoller = false;
              if (tmpElement[index]?.includes("$")) {
                tmpElement[index] = tmpElement[index]?.replaceAll("$", "");
                hasDoller = true;
              }
              tmpElement = resolvePrecision(
                [tmpElement],
                _originalConfig,
                datasheet,
                standardRanges,
                uncertaintyIndex
              );
              if (hasDoller) {
                element[index] = "$" + tmpElement[0][index];
              } else {
                element[index] = tmpElement[0][index];
              }
            } catch (err) {
              console.error("failed to process formula", err);
              element[index] = datasheetObservedReadingFallbackValue || "";
            }
            level--;
          } while (level > 0);
        }
      });
    });
  });
  return readings;
}

const resolvePrecision = (
  readings,
  config,
  datasheet,
  standardRanges,
  uncertaintyIndex
) => {
  config.forEach((config) => {
    let tableId = config.tableId;
    let tableIndex = config.index;
    let customPrecision = config.customPrecision;

    Object.keys(customPrecision || {})?.forEach((column) => {
      let tableSpecificReadings = readings.filter(reading => reading[0] == tableId)
      let index = -1;
      if (column === "uncertainty") {
        index = tableSpecificReadings[0]?.length + uncertaintyIndex;
      } else {
        index = Number(column.split("c")[1]) + BASE;
      }
      for (const element of readings) {
        // skip for invalid cases
        if (element[0] != tableId) continue;
        if (String(element[index])?.includes("_unit_")) continue;
        if (String(element[index])?.includes("_rh_")) continue;

        // gather data of right side columns
        let r_value = String(customPrecision[column]).split(".");
        let extraPrecision = Number(
          r_value[0].split(extraPrecisionSplitter)[1] || 0
        );
        r_value[0] = r_value[0].split(extraPrecisionSplitter)[0];
        let rValue = 0;
        let rValueDecimalCount = 0;

        if (r_value[0] != 7) {
          if (r_value[0].includes("manual")) {
            rValueDecimalCount = Number(r_value[1]);
          } else {
            if (r_value[0].includes("ds")) {
              r_value = r_value[0]; //eg r_value: ds1.1, ds2.null
              rValue =
                element[
                  Number(r_value?.split("ds")[1]?.split(".")?.[0]) + BASE
                ];
            } else {
              let rIndex = Number(r_value[0]);
              let stdRangePref = Number(r_value[1] || "0");
              if (r_value[0] == 6) {
                rValue = [
                  datasheet.lc,
                  getStandardRangeValue(
                    "lc",
                    standardRanges,
                    element[element.length - 2],
                    stdRangePref
                  ) || "0.0",
                ];
              } else {
                rValue = [
                  0,
                  0,
                  datasheet.lc,
                  getStandardRangeValue(
                    "lc",
                    standardRanges,
                    element[element.length - 2],
                    stdRangePref
                  ) || 0,
                  datasheet.accuracy,
                ][rIndex - 1];
              }
            }
            if (r_value[0] == 6) {
              rValue[0] = String(rValue[0])?.replace("\n", "").split("||");
              rValue[0] =
                rValue[0]?.length > tableIndex
                  ? rValue[0][tableIndex]
                  : rValue[0][0];
              rValue =
                (rValue[0].split(".")[1] || "").length >
                (rValue[1].split(".")[1] || "").length
                  ? rValue[0]
                  : rValue[1];
            } else {
              // pick 1 value from multi values if any
              rValue = String(rValue)?.replace("\n", "").split("||");
              rValue =
                rValue?.length > tableIndex ? rValue[tableIndex] : rValue[0];
            }

            // update reading col's value's precision according to soruce column
            rValueDecimalCount = 0;
            if (String(rValue).includes(".")) {
              rValueDecimalCount = String(rValue).split(".")[1];
              rValueDecimalCount = rValueDecimalCount.split("#")[0].length;
            }
          }
        }

        let _element = String(element[index]).replaceAll("$", "");
        let _newElement = null;
        if (_element !== "" && !isNaN(_element)) {
          if (r_value[0] == 7) {
            _newElement = Number(_element);
          } else {
            _newElement = Number(_element).toFixed(
              rValueDecimalCount + extraPrecision
            );
          }
        } else {
          _newElement = _element;
        }
        element[index] = String(element[index]).replace(
          _element,
          String(_newElement)
        );
      }
    });
  });
  return readings;
};

// datasheet ====================
export function prepareDatasheetReadings(props) {
  let {
    config,
    readings,
    datasheetObservedReadingFallbackValue,
    uncertaintyIndex,
    precisionCount,
    datasheet,
    standardRanges,
    referenceData,
  } = props;
  readings = resolveFormulas(
    readings,
    datasheetObservedReadingFallbackValue,
    config,
    precisionCount,
    datasheet,
    referenceData,
    standardRanges,
    uncertaintyIndex
  );
  let res = resolvePrecision(
    readings,
    config,
    datasheet,
    standardRanges,
    uncertaintyIndex
  );

  return res;
}

// certificates =================
function resolveRelations(datasheetReadings, config) {
  let certificateReadings;

  // init cert. readings
  let allCertificateReadings = [];
  config.forEach((config) => {
    let tableId = config.tableId;
    let totalColumns = Number(config.totalColumns);
    let relations = config.relations;

    certificateReadings = datasheetReadings.map((datasheetReading) => {
      if (datasheetReading[0] == tableId) {
        let certificateReading = [
          datasheetReading[0],
          datasheetReading[1],
          ...new Array(totalColumns).fill(null),
          datasheetReading[datasheetReading.length - 1],
        ];

        Object.keys(relations || {}).forEach((key, rIndex) => {
          let leftIndex = Number(key.split("c")[1]);
          let rightIndex = Number(relations[key].split("c")[1]);
          certificateReading[leftIndex + BASE] =
            datasheetReading[rightIndex + BASE];
        });
        if (datasheetReading[2]?.includes("_rh_")) {
          certificateReading[2] = datasheetReading[2];
        }
        return certificateReading;
      }
    });
    allCertificateReadings.push(...certificateReadings);
  });

  certificateReadings = allCertificateReadings.filter(
    (row) => row != undefined
  );

  return certificateReadings;
}

export function prepareCertificateReadings(props) {
  let { config, datasheetReadings } = props;
  //   initiate rows

  let updatedReadingRows = resolveRelations(datasheetReadings, config);
  // TODO: cross check: whether we really need formula in cert? then only uncomment
  // return resolveFormulas(updatedReadingRows, config, precisionCount)
  return updatedReadingRows;
}

// typeB ==============================
export function prepareTypeBValues(typeBConfiguration, datasheetReading) {
  let typeBValues = {};
  Object.keys(typeBConfiguration).forEach((key) => {
    // structure whould be {uncertaintyFactor : value, ...}
    typeBValues[typeBConfiguration[key]] = datasheetReading[key];
  });
  return JSON.stringify(typeBValues);
}

// generic ============================
export async function getDateTimeFromServer(time = false) {
  try {
    const url = `${BASE_URL}dynamic`;
    const query = `SELECT now() as date`;
    const response = await axiosWithToken.post(url, { query });
    let dateTime = new Date(response.data[0]?.date || null);
    if (time) {
      dateTime = {
        hour: dateTime.getHours(),
        minute: dateTime.getMinutes(),
        second: dateTime.getSeconds(),
      };
    }
    return dateTime;
  } catch (error) {
    console.error(error);
    return 0;
  }
}

export const withUnit = (val, unit) => {
  if (String(val).includes("_unit_") || String(val).includes("_unit_"))
    return val;
  return `${String(val)}#${
    String(unit).includes("_unit_") ? unit.split("_unit_")[1] : unit
  }`;
};
