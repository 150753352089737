import React, { useContext, useEffect, useState } from "react";
import { DatasheetContext } from "../datasheet.context";
import { BASE_URL, MAX_COLS } from "../../../../global";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  UNCERTAINTY_LINK,
  EXTRA_COLUMN_LINK,
} from "../../../master/staticTable/editTable";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  Modal,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandCircleDownOutlined from "@mui/icons-material/ExpandCircleDownOutlined";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import {
  ClassicTable,
  ClassicTableDatasheet,
} from "../../../../utils/components/Styles";
import { showComponent } from "../../../helper/helpers";
import StandardMasterSelection from "../StandardMasterSelection";
import {
  fetchCertificateStaticTables,
  fetchDatasheetStaticTables,
  fetchOtherStaticReadingRows,
  fetchStaticReadingRows,
} from "../fetchHandlers";
import { Box } from "@mui/system";
import RowDetailsModal from "./rowDetailsModal";
import axiosWithToken from "../../../../utils/components/axiosTokenConfig";
import { multiValueModalStyle } from "../../../srf/editSrf/constants";
import { range } from "mathjs";

export default function ObservedReadings(props) {
  const { datasheetId, isPrinting } = props;

  const {
    state: {
      datasheetDetails,
      datasheetStaticReadings,
      staticReadingRows,
      certificateStaticReadings,
      staticTables,
      certificateStaticTables,
      standardRanges,
      standardMasterArray,
      unitSymbols,
      indicators,
      selectedSupportiveRanges,
      supportiveStandardMasterArray,
      settings,
      isProcess,
      DatasheetReadingsAdditionalInfo,
    },
    dispatch,
  } = useContext(DatasheetContext);

  const [readingRowsLocal, setReadingRowsLocal] = useState([]);
  const [selectedRangeIds, setSelectedRangeIds] = useState([]);
  const [certificateReadingIds, setCertificateReadingIds] = React.useState({}); // dictionary to map datasheet id with certificate id
  const [derivedColumns, setDerivedColumns] = React.useState([]);
  const [tableConfig, setTableConfig] = React.useState({});
  const [deletedIds, setDeletedIds] = useState([]);
  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const [activeTable, setActiveTable] = useState(-1);
  const [precNotEqTracker, setPrecNotEqTracker] = useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [instructionModal, setInstructionModal] = React.useState(false);
  const [instructionInfo, setInstructionInfo] = React.useState("");

  // tableInstructionsmodal
  const renderInstruntionModal = () => {
    return (
      <Modal
        open={instructionModal}
        onClose={() => {
          setInstructionModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={{ ...multiValueModalStyle, background: "white" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: "5px",
            }}
          >
            <h4>Instructions</h4>
            <Button
              variant="contained"
              size="small"
              onClick={() => setInstructionModal(false)}
            >
              X
            </Button>
          </Box>
          <Box sx={{ my: 2 }}>
            <pre>
              <h6>{instructionInfo}</h6>
            </pre>
          </Box>
        </Box>
      </Modal>
    );
  };

  // utility functions
  const getCustomColumnCount = (
    tableId,
    isStatic = null,
    isCertificate = null,
    tables = null
  ) => {
    let tbl = tables;
    if (!tbl) {
      tbl =
        isStatic === "static"
          ? isCertificate === "certificate"
            ? certificateStaticTables
            : staticTables
          : null;
    }

    let count = 0;
    if (!tbl) return count;
    for (let i = 0; i < tbl.length; i++) {
      if (tbl[i].id == tableId) {
        for (let j = 0; j < MAX_COLS; j++) {
          if (tbl[i]["c" + (j + 1)] != null) {
            count++;
          } else {
            break;
          }
        }
      }
    }
    return count;
  };

  const getMaxRangeColValue = (tableId) => {
    let staticTable = staticTables.find((table) => table.id === tableId);
    let defaultConfig = JSON.parse(staticTable["defaultConfiguration"] || "{}");
    let rangeCol = Number(defaultConfig["rangeCol"]?.split("c")[1]) || -1;
    if (rangeCol === -1) return 0;

    let maxValue = 0;
    readingRowsLocal.forEach((row) => {
      if (row[0] === tableId) {
        let value = row[rangeCol + 1];
        if (value && value !== "NA") {
          value = value.replaceAll("$", "");
          value = Number(value);
          if (value > maxValue) maxValue = value;
        }
      }
    });

    return maxValue;
  };

  const checkMaster = (index) => {
    let rangeId = readingRowsLocal[index][readingRowsLocal[index].length - 2];
    rangeId =
      rangeId && rangeId !== "" && rangeId?.length > 0
        ? rangeId?.split(",")
        : [];
    rangeId = rangeId.filter((e) => e !== "");

    if (rangeId.length) return true;
    return false;
  };

  const validateSelectedStandardRange = (range, tableId) => {
    // 0. check is table is enabled for out of range validation
    if (
      !settings?.["DS Row: standard master validation"] ||
      settings?.["DS Row: standard master validation"] === "false"
    )
      return;

    console.log("validateSelectedStandardRange: ", range, tableId);

    // 1. validate LC precision count against standard least count
    let lc = range.lc?.split("#")[0];
    let std_lc = datasheetDetails.lc?.split("#")[0];

    // 2. calculate range lc precision count
    let lc_precision_count = 0;
    if (lc?.includes(".")) {
      lc_precision_count = lc.split(".")[1].length;
    }

    // 3. calculate standard lc precision count
    let std_lc_precision_count = 0;
    if (std_lc?.includes(".")) {
      std_lc_precision_count = std_lc.split(".")[1].length;
    }

    // 4. compare both precision count
    if (lc_precision_count < std_lc_precision_count) {
      dispatch({
        field: "indicators",
        value: {
          ...indicators,
          invalidLCPrecision: true,
        },
      });
    }
  };

  const updateSelectedStandardMasterIds = (selectedRangeIds, tableId) => {
    // 1. extract selected standard ids from selected range ids
    let selectedStandardIds = standardRanges.map((range) => {
      let status = false;
      selectedRangeIds
        ?.filter((item) => item)
        ?.forEach((rangeIds) => {
          rangeIds = rangeIds?.split(",").map((e) => e.split(":")[1]);
          if (rangeIds?.includes(String(range.rangeId)) === true) status = true;
        });
      if (status === true) {
        validateSelectedStandardRange(range, tableId);
        return range.id;
      } else return null;
    });

    // 2. update selected standard ids
    let _selectedStandardIds = selectedStandardIds.filter((e) => e > 0);
    _selectedStandardIds = [
      ...new Set([..._selectedStandardIds, ...selectedStandardIds]),
    ];

    dispatch({
      field: "selectedStandardIds",
      value: [..._selectedStandardIds],
    });
  };

  let parseRows = (tableRows) => {
    let base = 2;
    return Object.values(tableRows).map((rows) =>
      rows.map((row, idx) =>
        row.map((col, col_id) =>
          col_id >= base && col_id < row.length - 2 && col
            ? col.split("#")[0]
            : col
        )
      )
    );
  };

  const initiateStaticReadingRows = () => {
    if (!(datasheetStaticReadings)) return null;
    let rows = {};
    let _selectedRangeIds = selectedRangeIds;
    let tS = [];
    let rowUnitMap = {};
    let CurrentUnitRow = -1;
    let _certificateReadingIds = {
      ...certificateReadingIds,
    };

    let tableMap = {};
    staticTables?.map((s) => (tableMap[s.id] = s));

    datasheetStaticReadings.forEach((reading, index) => {
      let row = [];
      row.push(reading.tableId);
      row.push(reading.datasheetId);

      if (!rowUnitMap[reading.tableId]) {
        rowUnitMap[reading.tableId] = {};
      }
      if (reading["c1"].includes("_unit_")) {
        CurrentUnitRow = [reading.id, reading.uncertainty];
      } else {
        rowUnitMap[reading.tableId][reading.id] = CurrentUnitRow;
      }

      // check for readonly value and prepend $ if not present
      for (let i = 0; i < MAX_COLS; i++) {
        let value = reading["c" + (i + 1)];
        if (value != null) {
          if (value.includes("_unit_") || value.includes("_rh_")) {
            row.push(value);
          } else {
            if (value === "NA") value = "NA";
            else {
              let prefix = `${
                derivedColumns[reading.tableId]?.includes("c" + (i + 1)) ||
                tableMap[reading.tableId]?.[`c${i + 1}`]?.includes(
                  UNCERTAINTY_LINK
                )
                  ? "$"
                  : ""
              }`;
              value = value.replaceAll("$", "");
              value = prefix + value;
            }
            row.push(value);
          }
        } else {
          break;
        }
      }

      // set readonly $ for uncertainty
      if (
        reading?.uncertainty?.includes("_unit_") ||
        reading?.uncertainty?.includes("_rh_")
      ) {
        row.push(reading?.uncertainty);
      } else {
        row.push(("$" + reading?.uncertainty).replace(/\$+/g, "$"));
      }

      row.push(reading.standardRanges);
      row.push(reading.id);
      _certificateReadingIds[reading.id] = certificateStaticReadings[index]?.id;
      // IMP NOTE:
      // this is highly sensitive array, before adding any new fields to row, please check the consequences and impacting area specially static indexing
      if (rows[reading.tableId]?.length > 0) {
        rows[reading.tableId] = [...rows[reading.tableId], [...row]];
      } else {
        rows[reading.tableId] = [[...row]];
      }
      tS.push(reading.supportiveRanges);

      // set current ranges to selectedRangeIds
      _selectedRangeIds[index] = reading.standardRanges;
    });

    // set default vales if any

    rows = parseRows(rows);

    dispatch({
      field: "selectedSupportiveRanges",
      value: [...tS],
    });
    setSelectedRangeIds(_selectedRangeIds);

    let allRows = [];
    for (const tableId in rows) {
      if (rows[tableId]) allRows.push(...rows[tableId]);
    }
    setReadingRowsLocal([...allRows]);
    dispatch({ field: "staticReadingRows", value: [...allRows] });
    setCertificateReadingIds({ ..._certificateReadingIds });
  };

  const addStaticReadingRow = (
    tableIndex,
    tableId,
    rowIndex = null,
    type = null
  ) => {
    let newReadingRows = [...readingRowsLocal];
    let totalColumns = getCustomColumnCount(tableId, "static");

    let row = [tableId, Number(datasheetId)].concat(
      Array(totalColumns).fill("")
    );
    let tableMap = {};
    staticTables?.map((s) => (tableMap[s.id] = s));
    row = row.map((e, i) =>
      i !== 0 && i !== 1
        ? e === "NA"
          ? ""
          : `${
              derivedColumns[tableId].includes("c" + (i - 1)) ||
              tableMap[tableId][`c${i - 1}`]?.includes(UNCERTAINTY_LINK)
                ? "$"
                : ""
            }${e}`
        : e
    );
    // if (unceraintyConfig[tableId].isUncertainty)
    row.push("$"); // uncertainty
    row.push(null); // standard range
    row.push(0); // database row id

    // set default value to rows if any
    if (
      tableConfig[tableId].defaultValues &&
      Object.keys(tableConfig[tableId].defaultValues).length
    ) {
      let tmpRows = [];
      Object.keys(tableConfig[tableId].defaultValues).forEach((val, index) => {
        let col = val;
        let values = (tableConfig[tableId].defaultValues[col] || "")
          .split(",")
          .map((c) => c.trim());

        for (let i = 0; i < values.length; i++) {
          if (tmpRows.length <= i) {
            tmpRows.push([...row]);
          }
          tmpRows[i][1 + Number(col.split("c")[1])] = `$${values[i]}`;
        }
      });
      row = [...tmpRows];
    } else {
      row = [row];
    }

    if (type === "copy") {
      row[0][totalColumns + 3] = readingRowsLocal[rowIndex][totalColumns + 3];
      let tS = [...selectedSupportiveRanges];
      tS.push(tS[rowIndex]);
      dispatch({
        field: "selectedSupportiveRanges",
        value: [...tS],
      });
    }

    newReadingRows = newReadingRows.concat([...row]);
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const addStaticUnitRow = (tableIndex, tableId) => {
    let newReadingRows = [...readingRowsLocal];
    let totalColumns = getCustomColumnCount(tableId, "static") + 1; // for custom + uncertainty

    let row = [tableId, datasheetId].concat(Array(totalColumns).fill("_unit_"));
    if (tableConfig[tableId].isUncertainty) row.push(null); // standard range
    row.push(0);
    newReadingRows.push(row);
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const addStaticRowHeader = (tableIndex, tableId) => {
    let newReadingRows = [...readingRowsLocal];
    let totalColumns = getCustomColumnCount(tableId, "static") + 1; // for custom + uncertainty

    let row = [tableId, datasheetId].concat(Array(totalColumns).fill("_rh_")); //_rh_ : row header
    if (tableConfig[tableId].isUncertainty) row.push(null); // standard range
    row.push(0);
    newReadingRows.push(row);
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
  };

  const deleteStaticReadingRow = (rowIndex, tableId) => {
    let newReadingRows = [...readingRowsLocal];
    if (newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1])
      Promise.all([
        axiosWithToken.delete(
          BASE_URL +
            `datasheetStaticReadings/${
              newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1]
            }`,
          { status: -1 }
        ),
        axiosWithToken.delete(
          BASE_URL +
            `certificateStaticReadings/${
              certificateReadingIds[
                newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1]
              ]
            }`,
          { status: -1 }
        ),
      ])
        .then((res) => {
          toast.success("Row deleted successfully!");
        })
        .catch((err) => {
          toast.error("Failed to delete row, error:" + err);
        });

    setDeletedIds([
      ...deletedIds,
      newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1],
    ]);

    newReadingRows.splice(rowIndex, 1);
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });

    const remainingAdditionalInfo = DatasheetReadingsAdditionalInfo?.filter(
      (item) => item?.rowIndex !== rowIndex || item?.tableId !== tableId
    );

    dispatch({
      field: "DatasheetReadingsAdditionalInfo",
      value: remainingAdditionalInfo,
    });
  };

  const updateStaticCellValue = async (rowIndex, cellIndex, value) => {
    let newReadingRows = [...readingRowsLocal];
    if (cellIndex === 0 && value.includes("_unit_")) {
      newReadingRows[rowIndex][cellIndex + 2] = value;
      let tmp = newReadingRows[rowIndex].map((e, i) =>
        i > 1 &&
        i < newReadingRows[rowIndex].length - 2 &&
        e.replaceAll("_unit_", "").length === 0
          ? value
          : e
      );
      newReadingRows[rowIndex] = tmp;
    } else newReadingRows[rowIndex][cellIndex + 2] = value;
    setReadingRowsLocal([...newReadingRows]);
    dispatch({ field: "staticReadingRows", value: [...newReadingRows] });
    updateComplianceStatus(true);
    setActiveRowIndex(-1);
    setActiveTable(-1);
    if (isProcess) {
      dispatch({
        field: "isProcess",
        value: false,
      });
    }
  };

  const updateStandardRangesOfRow = (value, index) => {
    if (index === -1) {
      let _selectedSupportiveRanges = [...selectedSupportiveRanges];
      _selectedSupportiveRanges[activeRowIndex] = value;
      dispatch({
        field: "selectedSupportiveRanges",
        value: [..._selectedSupportiveRanges],
      });
      return;
    }

    let _staticReadingRows = [...readingRowsLocal];
    // TODO: correct below logic, as it is highly coupled to index which it should not at all !!!
    let rangeIds =
      _staticReadingRows[activeRowIndex][
        _staticReadingRows[activeRowIndex].length - 2
      ];
    rangeIds = String(rangeIds || "").split(",");

    rangeIds[index - 1] = value;

    _staticReadingRows[activeRowIndex][
      _staticReadingRows[activeRowIndex].length - 2
    ] = rangeIds.join(",");

    setReadingRowsLocal([..._staticReadingRows]);
    dispatch({ field: "staticReadingRows", value: [..._staticReadingRows] });

    let _selectedRangeIds = selectedRangeIds;
    _selectedRangeIds[activeRowIndex] = rangeIds.join(",");
    setSelectedRangeIds(_selectedRangeIds);

    let tableId = _staticReadingRows[activeRowIndex][0];
    updateSelectedStandardMasterIds(_selectedRangeIds, tableId);
  };

  const updateComplianceStatus = (complianceStatus, rowIndex = -1) => {
    if (indicators?.complianceStatus !== complianceStatus) {
      dispatch({
        field: "indicators",
        value: { ...indicators, complianceStatus: complianceStatus },
      });
    }
    if (
      rowIndex > -1 &&
      indicators?.rowWiseComplianceStatus[rowIndex] !== complianceStatus
    ) {
      dispatch({
        field: "indicators",
        value: {
          ...indicators,
          rowWiseComplianceStatus: {
            ...indicators.rowWiseComplianceStatus,
            [rowIndex]: complianceStatus,
          },
        },
      });
    }
  };

  const deleteTable = (tableId) => {
    // append into previoud ids
    let data = {
      deletedTableIds:
        (datasheetDetails?.deletedTableIds
          ? datasheetDetails?.deletedTableIds + ","
          : "") + tableId,
    };
    Promise.all([
      axiosWithToken
        .patch(BASE_URL + `datasheets/${datasheetId}`, data)
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        }),
      axiosWithToken
        .patch(BASE_URL + `certificates/${datasheetId}`, data)
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        }),
    ])
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.error(`[ERROR] Failed to delete table, error: ${err}`);
      });
  };

  useEffect(() => {
    if (standardRanges) fetchStaticReadingRows(datasheetId, dispatch);
  }, [standardRanges]);

  useEffect(() => {
    if (
      datasheetStaticReadings?.length > 0
    ) {
      initiateStaticReadingRows();
    }
  }, [datasheetStaticReadings, certificateStaticReadings]);

  useEffect(() => {
    if (datasheetDetails?.instrumentId < 1) return;

    fetchDatasheetStaticTables(
      datasheetDetails.instrumentId,
      datasheetDetails.deletedTableIds, //TODO: pass static tables deleted ids only
      setDerivedColumns,
      setTableConfig,
      dispatch
    );
    fetchCertificateStaticTables(
      datasheetDetails.instrumentId,
      datasheetDetails.deletedTableIds, // considered as certificated table id as both have same table ids
      dispatch
    );
  }, [datasheetDetails.instrumentId, datasheetDetails.deletedTableIds]);

  useEffect(() => {
    if (staticReadingRows != readingRowsLocal) {
      setReadingRowsLocal([...staticReadingRows]);
    }
  }, [staticReadingRows]);

  // render block
  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null;
    defaultConfiguration = JSON.parse(defaultConfiguration);
    let hasCommonHeader = defaultConfiguration.hasCommonHeader;
    if (hasCommonHeader && defaultConfiguration.commonHeaderConfig) {
      let commonHeaderConfig =
        defaultConfiguration.commonHeaderConfig?.split(",");
      let cols = [];
      commonHeaderConfig.forEach((col, index) => {
        let [name, size] = col?.split(":");
        let classNames =
          "header-border " + (name != "$" ? "header-border-bottom" : "");
        cols.push(
          <TableCell
            key={"common-header-" + index}
            className={!isPrinting ? classNames : ""}
            align="center"
            style={{ textAlign: "center" }}
            colSpan={Number(size)}
          >
            {name != "$" ? name : ""}
          </TableCell>
        );
      });

      return (
        <TableRow>
          {/* for SN */}
          {!isPrinting && (
            <>
              <TableCell
                className={isPrinting ? "header-border-print" : "header-border"}
                align="center"
              ></TableCell>
              <TableCell
                className={isPrinting ? "header-border-print" : "header-border"}
                align="center"
              ></TableCell>
            </>
          )}
          {cols}
        </TableRow>
      );
    }
    return null;
  };

  const renderUnit = (value, onChange) => {
    return (
      <div id="datasheet_editdatasheet_readingrow_selectunit">
        {isPrinting ? (
          <Typography variant="p">{value}</Typography>
        ) : (
          <select name="units" id="units" onChange={onChange} value={value}>
            <option value="">Select Unit</option>
            {unitSymbols?.map((unit) => {
              return (
                <option value={unit} key={unit}>
                  {unit}
                </option>
              );
            })}
          </select>
        )}
      </div>
    );
  };

  const getStandardRangeLC = (
    standardRangeId,
    stdRangePref,
    isRange,
    tableIndex
  ) => {
    standardRangeId = standardRangeId?.split(",").map((e) => e.split(":")[1])[
      stdRangePref > 0 ? stdRangePref - 1 : 0
    ];

    let range = standardRanges?.filter(
      (range) => range.rangeId === Number(standardRangeId)
    );
    let duc_ranges;
    if (isRange) {
      duc_ranges = datasheetDetails?.ranges?.split("||");
      if (duc_ranges?.length > 1) {
        duc_ranges = duc_ranges[tableIndex || 0];
      } else duc_ranges = duc_ranges[0];
      range = range?.[0]?.range
        ? Math.max(
            ...(range[0]?.range
              .split("|")
              .map((e) => Number(e.split("#")[0]))
              .filter((e) => !isNaN(e)) || [])
          )
        : null;
      duc_ranges =
        duc_ranges !== undefined || duc_ranges !== null
          ? Math.max(
              ...(duc_ranges
                .split("|")
                .map((e) => Number(e.split("#")[0]))
                .filter((e) => !isNaN(e)) || [])
            )
          : null;

      if (range && range !== null && duc_ranges && duc_ranges !== null)
        range = Math.min(range, duc_ranges);
      else if (!range && duc_ranges) range = duc_ranges;
    }

    return isRange ? range : range?.[0]?.lc.split("#")[0];
  };

  const getConditionalFormattedColor = (
    value,
    cellIndex,
    table,
    tableIndex,
    rowIndex
  ) => {
    value = `${value}`.replaceAll("$", "");
    let { relations } = JSON.parse(table?.conditionFormatting || "{}");
    let dsCol;
    let config = relations["c" + cellIndex];
    if (relations) {
      if (config) {
        let _dsCol = config.dsCol.split(".");
        dsCol = !String(_dsCol[0])?.includes("ds")
          ? Number(_dsCol[0])
          : _dsCol[0];
        let stdRangePref = Number(_dsCol[1] || "0");

        let valToCompare = 0;
        if (String(dsCol)?.includes("ds")) {
          _dsCol = dsCol.split("ds")[1];
          valToCompare = readingRowsLocal[rowIndex][Number(_dsCol) + 2 - 1];
        } else {
          let possibleValues = [
            0,
            getStandardRangeLC(
              readingRowsLocal[rowIndex][readingRowsLocal[rowIndex].length - 2],
              stdRangePref,
              true,
              tableIndex
            ) || 0,
            datasheetDetails.lc,
            getStandardRangeLC(
              readingRowsLocal[rowIndex][readingRowsLocal[rowIndex].length - 2],
              stdRangePref
            ) || 0,
            datasheetDetails.accuracy,
          ];
          valToCompare = possibleValues[dsCol - 1];
        }

        valToCompare = String(valToCompare)
          ?.replace("\n", "")
          .replaceAll("$", "")
          .split("||");

        valToCompare =
          valToCompare?.length > tableIndex
            ? valToCompare[tableIndex]
            : valToCompare[0];

        // remove unit if it contains
        valToCompare = valToCompare?.split("#")[0];

        if (
          config.condition?.toLowerCase() === "lt" &&
          Number(dsCol == 2 ? value : Math.abs(value)) <
            Number(dsCol == 2 ? valToCompare : Math.abs(valToCompare))
        ) {
          updateComplianceStatus(false, rowIndex);
          return config.color;
        } else if (
          config.condition?.toLowerCase() === "gt" &&
          Number(dsCol == 2 ? value : Math.abs(value)) >
            Number(dsCol == 2 ? valToCompare : Math.abs(valToCompare))
        ) {
          updateComplianceStatus(false, rowIndex);

          // alert("Calibration Point Can't be greater than Master Range!");
          if (
            dsCol === 2 &&
            !indicators.outOffRangeTracker[
              `${tableIndex}_${rowIndex}_${cellIndex}`
            ]
          ) {
            dispatch({
              field: "indicators",
              value: {
                ...indicators,
                outOffRangeTracker: {
                  ...indicators.outOffRangeTracker,
                  [`${tableIndex}_${rowIndex}_${cellIndex}`]: true,
                },
              },
            });
          }
          return config.color;
        } else if (
          config.condition?.toLowerCase() === "precisioncountnotequal"
        ) {
          value = value.toString();
          let valueDecimalCount = value.includes(".")
            ? value.split(".").pop().length
            : 0;
          valToCompare = valToCompare.toString();
          let valToCompareDecimalCount = 0;
          if (String(valToCompare).includes(".")) {
            valToCompareDecimalCount = String(valToCompare).split(".")[1];
            valToCompareDecimalCount =
              valToCompareDecimalCount.split("#")[0].length;
          }

          if (valueDecimalCount !== valToCompareDecimalCount) {
            updateComplianceStatus(false, rowIndex);
            if (!precNotEqTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]) {
              setPrecNotEqTracker({
                ...precNotEqTracker,
                [`${tableIndex}_${rowIndex}_${cellIndex}`]: true,
              });
            }
            return config.color;
          }
        }
      }
    }
    if (
      dsCol == 2 &&
      indicators.outOffRangeTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]
    ) {
      let tmp = { ...indicators.outOffRangeTracker };
      delete tmp[`${tableIndex}_${rowIndex}_${cellIndex}`];
      dispatch({
        field: "indicators",
        value: {
          ...indicators,
          outOffRangeTracker: tmp,
        },
      });
    }
    if (
      config?.condition?.toLowerCase() === "precisioncountnotequal" &&
      precNotEqTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]
    ) {
      let tmp = { ...precNotEqTracker };
      delete tmp[`${tableIndex}_${rowIndex}_${cellIndex}`];
      setPrecNotEqTracker(tmp);
    }
    return "";
  };

  const renderTableCell = (
    table,
    tableIndex,
    row,
    rowIndex,
    cell,
    cellIndex,
    onChange = {}
  ) => {
    let referenceImageUpload =
      (table?.defaultConfiguration &&
        JSON.parse(table?.defaultConfiguration)?.referenceImageUpload) ||
      "";

    let imageReferenceIndex = [];
    if (referenceImageUpload) {
      imageReferenceIndex = referenceImageUpload.split(",").map((value) => {
        const number = parseInt(value.substring(1));
        return number - 1;
      });
    }

    const handleFileUpload = (
      event,
      rowIndex,
      cellIndex,
      table,
      tableIndex,
      row
    ) => {
      const file = event.target.files[0];
      const data = new FormData();
      data.append("fileToUpload", file);

      function updateDatasheetReadings(
        table,
        DatasheetReadingsAdditionalInfo,
        cellIndex,
        URL,
        rowIndex
      ) {
        let payload = {
          tableId: table?.id,
          datasheetReadingId: Number(datasheetId),
          rowIndex: rowIndex,
        };

        payload[`c${cellIndex + 1}`] = JSON.stringify({ image: URL });

        let updatedDatasheetReadingsAdditionalInfo = [
          ...DatasheetReadingsAdditionalInfo,
        ]; // Clone the original array

        // Find if there's an object with the same tableId, datasheetReadingId, and rowIndex as the payload
        const existingEntryIndex =
          updatedDatasheetReadingsAdditionalInfo.findIndex(
            (entry) =>
              entry.tableId === payload.tableId &&
              entry.datasheetReadingId === payload.datasheetReadingId &&
              entry.rowIndex === payload.rowIndex
          );

        if (existingEntryIndex !== -1) {
          // If an object with the same tableId, datasheetReadingId, and rowIndex exists, update its c1 value
          updatedDatasheetReadingsAdditionalInfo[existingEntryIndex][
            `c${cellIndex + 1}`
          ] = payload[`c${cellIndex + 1}`];
        } else {
          // If not, create a new object and add it to the array
          const newEntry = {
            tableId: payload.tableId,
            datasheetReadingId: payload.datasheetReadingId,
            rowIndex: payload.rowIndex,
          };
          newEntry[`c${cellIndex + 1}`] = payload[`c${cellIndex + 1}`];

          updatedDatasheetReadingsAdditionalInfo.push(newEntry);
        }

        dispatch({
          field: "DatasheetReadingsAdditionalInfo",
          value: [...updatedDatasheetReadingsAdditionalInfo],
        });
      }

      // updateDatasheetReadings(table, DatasheetReadingsAdditionalInfo, cellIndex,"https://e7.pngegg.com/pngimages/958/586/png-clipart-laboratory-centrifuge-magnetic-stirrer-calibration-others-electronics-laboratory-thumbnail.png",rowIndex)

      axiosWithToken
        .post(BASE_URL + `fileUpload/fileUpload.php`, data)
        .then(async (res) => {
          if (res.status === 200) {
            // console.log("res",res.data)
            await updateDatasheetReadings(
              table,
              DatasheetReadingsAdditionalInfo,
              cellIndex,
              res.data,
              rowIndex
            );
            toast.success("File Uploaded Successfully!");
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something Went Wrong!");
        });
    };

    const VisuallyHiddenInput = styled("input")`
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      white-space: nowrap;
      width: 1px;
    `;

    let field = null;

    // 1. it its unit field
    if (String(cell)?.includes("_unit_")) {
      //removed check to check to show text field instead of unit for m-x
      let value = cell.split("_unit_")[1];
      field = renderUnit(value, (e) => {
        updateStaticCellValue(rowIndex, cellIndex, "_unit_" + e.target.value);
      });
    }
    // 2. if its read only value field
    else if (cell && cell[0] == "$") {
      field = (
        <span
          style={{
            color: getConditionalFormattedColor(
              row[cellIndex + 2],
              cellIndex + 1,
              table,
              tableIndex,
              rowIndex
            ),
          }}
        >
          {cell.slice(1)}
        </span>
      );
    } else if (cell == "null" && cell == null) {
      field = (
        <TextField
          size="small"
          value={""}
          InputProps={{
            style: {
              fontSize: "12px",
              margin: "0px",
              padding: "0px",
            },
          }}
          onChange={(e) => {
            updateStaticCellValue(rowIndex, cellIndex, e.target.value);
          }}
          variant="standard"
        ></TextField>
      );
    }

    // 3. if its a value input field
    else {
      const isImageUploadCell = imageReferenceIndex?.includes(cellIndex);
      const matchingObjects = DatasheetReadingsAdditionalInfo?.filter(
        (obj) => obj.rowIndex === rowIndex && obj.tableId === table?.id
      )?.[0];
      // Increment cellIndex by 1
      const nextCellIndex = cellIndex + 1;

      // Construct the key to check
      const keyToCheck = "c" + nextCellIndex;

      // Check if the key exists in matchingObjects and if it has a value
      const hasValue = matchingObjects
        ? keyToCheck in matchingObjects &&
          matchingObjects[keyToCheck] !== undefined
        : false;

      field = (
        <>
          {isPrinting ? (
            <Typography variant="p">{row[cellIndex + 2]}</Typography>
          ) : (
            <div style={{ display: "flex" }}>
              <TextField
                id="datasheet_editdatasheet_readingrow_enterReading"
                size="small"
                sx={{
                  input: {
                    color: getConditionalFormattedColor(
                      row[cellIndex + 2],
                      cellIndex + 1,
                      table,
                      tableIndex,
                      rowIndex
                    ),
                  },
                }}
                value={row[cellIndex + 2]}
                InputProps={{
                  style: {
                    fontSize: "12px",
                    margin: "0px",
                    padding: "0px",
                  },
                }}
                onChange={async (e) => {
                  updateStaticCellValue(rowIndex, cellIndex, e.target.value);
                }}
                variant="standard"
              ></TextField>
              {isImageUploadCell && (
                <IconButton
                  id="create_document_uploadfile"
                  component="label"
                  variant="contained"
                  key={DatasheetReadingsAdditionalInfo}
                >
                  <CloudUploadIcon
                    style={{
                      fontSize: "16px",
                      color: hasValue ? "#21b921" : "none",
                    }}
                  />
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) =>
                      handleFileUpload(
                        e,
                        rowIndex,
                        cellIndex,
                        table,
                        tableIndex,
                        row
                      )
                    }
                  />
                </IconButton>
              )}
            </div>
          )}
        </>
      );
    }
    return (
      <TableCell
        key={cellIndex}
        style={{
          padding: "4px",
          fontSize: "11px",
        }}
        variant="standard"
      >
        {field}
      </TableCell>
    );
  };

  const renderActions = (
    row,
    rowIndex,
    range,
    tableId,
    tableIndex,
    table,
    hasActions = false
  ) => {
    const handleClose = () => {
      dispatch({
        field: "staticReadingRows",
        value: readingRowsLocal,
      });
      setOpenModal(false);
    };

    return (
      <ButtonGroup size="small" aria-label="small button group">
        {hasActions && (
          <>
            <Button
              id="datasheet_editdatasheet_readingrow_copy"
              onClick={(e) => {
                addStaticReadingRow(tableIndex, tableId, rowIndex, "copy");
              }}
            >
              <ContentCopyIcon />
            </Button>
            {isMobile && (
              <Button
                onClick={() => {
                  setOpenModal(true);
                  setSelectedRowIndex(rowIndex);
                }}
              >
                <PreviewIcon />
              </Button>
            )}
          </>
        )}

        <RowDetailsModal
          open={openModal}
          handleClose={handleClose}
          staticTables={staticTables}
          staticReadingRows={readingRowsLocal}
          tableIndex={tableIndex}
          rowIndex={selectedRowIndex}
          table={table}
          renderTableCell={renderTableCell}
          tableConfig={tableConfig}
          isPrinting={isPrinting}
        />
      </ButtonGroup>
    );
  };

  const renderRows = (table, tableIndex, staticReadingRows, range) => {
    let rows = [];
    let rowType = "";
    staticReadingRows?.forEach((row, rowIndex) => {
      let _row = [];
      if (row[0] == table.id) {
        if (String(row[2])?.includes("_rh_")) {
          rowType = "header";
          _row = (
            <TableRow key={"reading-row" + rowIndex} id="pagbreaksborder">
              {!isPrinting ? (
                <TableCell>
                  {renderActions(
                    row,
                    rowIndex,
                    range,
                    table.id,
                    tableIndex,
                    table
                  )}
                </TableCell>
              ) : null}
              <TableCell colSpan={row.length - 3 - (isPrinting ? 2 : 0)}>
                <TextField
                  id="datasheet_editdatasheet_readingrow_enterrrowheading"
                  size="small"
                  fullWidth
                  value={row[0 + 2].split("_rh_")[1]} // 2: row_base
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      margin: "auto",
                      padding: "0px",
                    },
                  }}
                  onChange={(e) => {
                    updateStaticCellValue(rowIndex, 0, "_rh_" + e.target.value);
                  }}
                ></TextField>
              </TableCell>
              {!isPrinting ? (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingrowheader_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        const confirmed = window.confirm(
                          "Are you sure you want to delete the row?"
                        );
                        if (confirmed) {
                          // TODO: correct below logic
                          deleteStaticReadingRow(rowIndex, table.id);
                          let tmp = { ...indicators.outOffRangeTracker };
                          let tmp2 = { ...indicators.outOffRangeTracker };
                          let tmp3 = { ...precNotEqTracker };
                          let tmp4 = { ...precNotEqTracker };
                          Object.keys(tmp).map((key) => {
                            if (key.includes(`${tableIndex}_${rowIndex}_`)) {
                              delete tmp2[key];
                            }
                          });
                          Object.keys(tmp3).map((key) => {
                            if (key.includes(`${tableIndex}_${rowIndex}_`)) {
                              delete tmp4[key];
                            }
                          });
                          dispatch({
                            field: "indicators",
                            value: {
                              ...indicators,
                              outOffRangeTracker: tmp2,
                            },
                          });
                          setPrecNotEqTracker(tmp4);
                        }
                      }}
                    />
                  )}
                </TableCell>
              ) : null}
            </TableRow>
          );
        } else if (String(row[2])?.includes("_unit_")) {
          rowType = "unit";
          _row = (
            <TableRow key={rowIndex} id="pagbreaksborder">
              {!isPrinting ? (
                <TableCell>
                  {renderActions(
                    row,
                    rowIndex,
                    range,
                    table.id,
                    tableIndex,
                    table
                  )}
                </TableCell>
              ) : (
                ""
              )}
              {!isPrinting && <TableCell></TableCell>}
              {row
                .slice(2, row.length - 2) // 2: tableId + datasheetId, 1: uncertainty, stdRanges
                .map((cell, cellIndex) =>
                  renderTableCell(
                    table,
                    tableIndex,
                    row,
                    rowIndex,
                    cell,
                    cellIndex
                  )
                )}
              {!isPrinting && (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingrowunit_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        const confirmed = window.confirm(
                          "Are you sure you want to delete the row?"
                        );
                        if (confirmed) {
                          deleteStaticReadingRow(rowIndex, table.id);
                        }
                      }}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          );
        } else {
          rowType = "data";
          _row = (
            <TableRow key={rowIndex} id="pagbreaksborder">
              {!isPrinting ? (
                <TableCell>
                  {renderActions(
                    row,
                    rowIndex,
                    range,
                    table.id,
                    tableIndex,
                    table,
                    true
                  )}
                </TableCell>
              ) : (
                ""
              )}
              {!isPrinting ? (
                <TableCell>
                  <button
                    id="datasheet_editdatasheet_readingrow_selectmaster"
                    style={{
                      color: checkMaster(rowIndex) ? "green" : "red",
                      rotate:
                        table.id === activeTable && rowIndex === activeRowIndex
                          ? "180deg"
                          : "0deg",
                    }}
                    onClick={() => {
                      if (activeTable === -1 || activeRowIndex === -1) {
                        setActiveRowIndex(rowIndex);
                        setActiveTable(table.id);
                      } else if (
                        activeTable === table.id &&
                        activeRowIndex === rowIndex
                      ) {
                        setActiveRowIndex(-1);
                        setActiveTable(-1);
                      } else {
                        setActiveRowIndex(rowIndex);
                        setActiveTable(table.id);
                      }
                    }}
                  >
                    <ExpandCircleDownOutlined />
                  </button>
                </TableCell>
              ) : (
                ""
              )}
              {row
                .slice(
                  2,
                  row.length -
                    (!isPrinting && tableConfig[table.id]?.isUncertainty
                      ? 2
                      : 3)
                ) // 2: tableId + datasheetId, 2: standardRanges, rowId
                .map((cell, cellIndex) =>
                  renderTableCell(
                    table,
                    tableIndex,
                    row,
                    rowIndex,
                    cell,
                    cellIndex
                  )
                )}

              {!isPrinting ? (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingrow_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        const confirmed = window.confirm(
                          "Are you sure you want to delete the row?"
                        );
                        if (confirmed) {
                          deleteStaticReadingRow(rowIndex, table.id);
                        }
                      }}
                    />
                  )}
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          );
        }
      }
      if (
        activeTable === table.id &&
        rowIndex == activeRowIndex &&
        rowType == "data"
      ) {
        let standardRow = (
          <tr id="datasheet_editdatasheet_readingrow_selectmasterrow">
            <td></td>
            <td></td>
            <TableCell colSpan={row.length - 2}>
              {" "}
              <StandardMasterSelection
                index={0}
                label="Standard master 1"
                standardRanges={standardRanges}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[0] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={1}
                label="Standard master 2"
                standardRanges={standardRanges}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[1] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={2}
                label="Standard master 3"
                standardRanges={standardRanges}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[2] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={3}
                label="Standard master 4"
                standardRanges={standardRanges}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[3] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={4}
                label="Supportive master"
                standardRanges={supportiveStandardMasterArray}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                isSupportive={true}
                valId={
                  (
                    (selectedSupportiveRanges[activeRowIndex] || "").split(
                      ","
                    )[0] || ""
                  ).split(":")[1] || ""
                }
              />
            </TableCell>
          </tr>
        );
        rows.push(_row, standardRow);
      } else {
        rows.push(_row);
      }
    });

    return rows;
  };

  const renderStaticTables = () => {
    if (!staticTables) return <Box sx={{ width: "100%" }}>Loading...</Box>;
    if (staticTables.length < 1)
      return <Box sx={{ width: "100%" }}>No tables found!</Box>;
    let EditandViewTable = isPrinting ? ClassicTableDatasheet : ClassicTable;

    let range = 0;
    return staticTables?.map((table, tableIndex) => {
      if (datasheetDetails.tableDisplayStatus === 1 && table.isAutomated !== 0)
        return null;
      else if (
        datasheetDetails.tableDisplayStatus === 2 &&
        table.isAutomated !== 1
      )
        return null;

      range = 0;
      return (
        <div
          key={tableIndex}
          sx={{ mb: 5 }}
          style={{
            marginBottom: !isPrinting ? "60px" : "20px",
            pageBreakInside: tableIndex === 0 ? "auto" : "avoid",
          }}
        >
          {!isPrinting ? (
            <>
              <Typography
                variant="h6"
                component="div"
                style={{ float: "left" }}
              >
                {tableIndex + 1 + ". "}
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="standard"
                  type="text"
                  value={
                    datasheetDetails?.configuration?.customTableNames?.[
                      table.id
                    ] || table.tableName
                  }
                  onChange={(e) => {
                    dispatch({
                      field: "datasheetDetials",
                      value: {
                        ...datasheetDetails,
                        configuration: {
                          ...datasheetDetails.configuration,
                          customTableNames: {
                            ...datasheetDetails.configuration.customTableNames,
                            [table.id]: e.target.value,
                          },
                        },
                      },
                    });
                  }}
                />
                <Button size="sm">
                  <InfoIcon
                    id="datasheet_editdatasheet_readingtablehead_info"
                    style={{
                      color: "#8d7a7c",
                    }}
                    onClick={(e) => {
                      setInstructionModal(true);
                      setInstructionInfo(table.tableInstructions);
                    }}
                  />
                </Button>
                {showComponent("delete") ? "|" : ""}
                {showComponent("delete") && (
                  <Button size="sm">
                    <DeleteIcon
                      id="datasheet_editdatasheet_readingtablehead_delete"
                      style={{
                        color: "#8d7a7c",
                      }}
                      onClick={(e) => {
                        const confirmed = window.confirm(
                          "Are you sure you want to delete the table?"
                        );
                        if (confirmed) deleteTable(table.id);
                      }}
                    />
                  </Button>
                )}
              </Typography>
            </>
          ) : (
            <Typography variant="body1" component="div">
              {datasheetDetails?.configuration?.customTableNames?.[table.id] ||
                table.tableName}
            </Typography>
          )}
          <div style={{ width: "100%", overflow: "auto" }}>
            <EditandViewTable>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  {renderCommonHeader(table?.defaultConfiguration)}
                  <TableRow>
                    {!isPrinting ? (
                      <>
                        <TableCell>
                          {table?.isUncertainty ? (
                            <Button
                              id="datasheet_editdatasheet_readingtablehead_expanded_uncertainty"
                              variant="outlined"
                              size="small"
                              style={{
                                fontSize: "12px",
                                width: "100px",
                              }}
                              onClick={() => {
                                let maxReadingRangeValue = getMaxRangeColValue(
                                  table.id
                                );
                                window.location.href = `/#/uncertainty/${datasheetId}/${datasheetDetails.instrumentId}/1/${table.id}/${maxReadingRangeValue}`;
                              }}
                            >
                              Expanded Uncertainty
                            </Button>
                          ) : null}
                        </TableCell>
                        <TableCell
                          className="header-border"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <Typography noWrap style={{ fontSize: "12px" }}>
                            Select Master
                          </Typography>
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}
                    {/* render columns*/}
                    {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                      if (table["c" + i]?.toLowerCase()?.match(/m+\d+/g)) {
                        range++;
                      }
                      if (table["c" + i] != null) {
                        return (
                          <TableCell
                            key={"static-table-column" + i}
                            className={
                              isPrinting
                                ? "header-border-print"
                                : "header-border"
                            }
                            align="center"
                            style={{ textAlign: "center" }}
                          >
                            <div>
                              <Typography
                                // noWrap
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                {table["c" + i]?.includes(UNCERTAINTY_LINK)
                                  ? table["c" + i]?.split(UNCERTAINTY_LINK)?.[0]
                                  : table["c" + i]?.includes(EXTRA_COLUMN_LINK)
                                  ? table["c" + i]?.split(
                                      EXTRA_COLUMN_LINK
                                    )?.[0]
                                  : table["c" + i]}
                              </Typography>
                            </div>
                          </TableCell>
                        );
                      }
                    })}
                    {!isPrinting && tableConfig[table.id]?.isUncertainty ? (
                      <TableCell>
                        <Typography
                          noWrap
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Uncertainty
                        </Typography>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {!isPrinting ? (
                      <TableCell
                        className={
                          isPrinting ? "header-border-print" : "header-border"
                        }
                        align="center"
                        style={{ textAlign: "center" }}
                      ></TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows(table, tableIndex, readingRowsLocal, range)}
                </TableBody>
              </Table>
            </EditandViewTable>
          </div>
          {!isPrinting ? (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  margin: "10px 0px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  id="datasheet_editdatasheet_readingadd"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticReadingRow(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Reading(s)
                </Button>
                <Button
                  id="datasheet_editdatasheet_readingunit"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticUnitRow(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Unit
                </Button>
                <Button
                  id="datasheet_editdatasheet_readingheader"
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    addStaticRowHeader(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Row header
                </Button>
              </div>
              <Divider
                sx={{
                  backgroundColor: "black",
                  height: "2px",
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const renderComplianceStatus = () => {
    let color = "black";
    let complianceStatus = "N/A";
    if (readingRowsLocal?.length > 0) {
      if (indicators?.complianceStatus == true) {
        color = "green";
        complianceStatus = "Pass";
      } else if (indicators?.complianceStatus == false) {
        color = "red";
        complianceStatus = "Fail";
      }
    }
    return (
      <div>
        <h5 style={{ color: color }}>
          Compliance Status:
          {complianceStatus}
        </h5>
      </div>
    );
  };

  return (
    <div>
      {!isPrinting && (
        <>
          <div>
            <Typography variant="h6" component="div">
              Observed Readings
            </Typography>
            {settings?.["Datasheet Autoload"] == "true" && (
              <Button
                size="sm"
                onClick={(e) => {
                  fetchOtherStaticReadingRows(
                    datasheetId,
                    datasheetStaticReadings,
                    datasheetDetails,
                    dispatch
                  );
                }}
              >
                Autoload
              </Button>
            )}
          </div>
          <hr style={{ border: "1px solid black" }} />
        </>
      )}

      <div className="ds-tables">{renderStaticTables()}</div>

      {!isPrinting && (
        <>
          <hr style={{ border: "1px solid black" }} />
          {renderComplianceStatus()}
        </>
      )}
      {renderInstruntionModal()}
    </div>
  );
}
